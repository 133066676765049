import BarLoader from "react-spinners/BarLoader";
import {Table} from "antd";
import React, {useEffect, useState} from "react";
import customAxios from "../../../utils/axios";
import {SERVER_URL} from "../../../Constants";


const MessagesChatMonitor = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Influencer",
      dataIndex: "influencerName",
      key: "influencerName",
      render: (text) => <div>{text}</div>
    },
    {
      title: "User ID",
      dataIndex: "user_id",
      key: "user_id",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Response Time Total",
      dataIndex: "responseTimeTotal",
      key: "responseTimeTotal",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Response Time Intent Engine",
      dataIndex: "responseTimeIntentEngine",
      key: "responseTimeIntentEngine",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Response Time Link Match",
      dataIndex: "responseTimeLinkMatch",
      key: "responseTimeLinkMatch",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Response Time Get Response",
      dataIndex: "responseTimeGetResponse",
      key: "responseTimeGetResponse",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Is Audio Returned",
      dataIndex: "audioReturned",
      key: "audioReturned",
      render: (number) => (number ? <div>Yes</div> : <div>No</div>)
    },
    {
      title: "Created At",
      dataIndex: "date",
      key: "date",
      render: (text) => <div>{text}</div>
    },
  ];

  useEffect(() => {
    const fetchData = () => {
      customAxios.get(SERVER_URL + "/chat-monitor/messages").then(function (res) {
        setData(res.data);
        setLoading(false);
      });
    }

    fetchData();

  }, []);

  return (
    <div>
      {loading &&
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      }
      {!loading &&
        <Table style={{marginTop: "8px"}} columns={columns} dataSource={data} rowKey="id"/>
      }
    </div>
  );
}

export default MessagesChatMonitor;
