import React from "react";
import { Button, Modal, Input, message } from "antd";
import axios from "axios";

const Reboot = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [password, setPassword] = React.useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/reboot`, { password });
      message.success("Reboot initiated successfully!");
    } catch (error) {
      message.error("Failed to initiate reboot. Please check your password.");
    } finally {
      setIsModalVisible(false);
      setPassword("");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setPassword("");
  };

  return (
    <div>
      <Button type="primary" onClick={showModal}>
        Reboot
      </Button>
      <Modal
        title="Confirm Reboot"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to reboot?</p>
        <Input.Password
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default Reboot;
