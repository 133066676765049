import React, {useEffect, useState} from "react";
import BarLoader from "react-spinners/BarLoader";
import {Table, Tabs} from "antd";
import customAxios from "../../../utils/axios";
import {SERVER_URL} from "../../../Constants";


const UsageChatMonitor = () => {
  const [loading, setLoading] = useState(true);
  const [activeKey, setActiveKey] = useState("1");
  const [data24Hours, setData24Hours] = useState([]);
  const [data7Days, setData7Days] = useState([]);
  const [data30Days, setData30Days] = useState([]);
  const [dataMTDDays, setDataMTDDays] = useState([]); // Month-to-date

  const columns = [
    {
      title: "Influencer",
      dataIndex: "influencerName",
      key: "influencerName",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Number of Total Users",
      dataIndex: "totalUsers",
      key: "totalUsers",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Number of New Users",
      dataIndex: "newUsers",
      key: "newUsers",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Number of chats",
      dataIndex: "totalChats",
      key: "totalChats",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Number of generated images",
      dataIndex: "totalImages",
      key: "totalImages",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Average GPU response time (excluding images)",
      dataIndex: "totalGPUResponseTime",
      key: "totalGPUResponseTime",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Average Total repone time (excluding images)",
      dataIndex: "totalResponseTime",
      key: "totalResponseTime",
      render: (text) => <div>{text}</div>
    },
  ];

  const items = [
    {
      key: "1",
      label: "Past 24 Hours",
      children: <Table key="id24Hours" style={{marginTop: "8px"}} columns={columns} dataSource={data24Hours} rowKey={(record) => record.key} />
    },
    {
      key: "2",
      label: "Past 7 Days",
      children: <Table key="id7Days" style={{marginTop: "8px"}} columns={columns} dataSource={data7Days} rowKey={(record) => record.key} />
    },
    {
      key: "3",
      label: "Past 30 Days",
      children: <Table key="id30Days" style={{marginTop: "8px"}} columns={columns} dataSource={data30Days} rowKey={(record) => record.key} />
    },
    {
      key: "4",
      label: "Month to Date",
      children: <Table key="idMTDDays" style={{marginTop: "8px"}} columns={columns} dataSource={dataMTDDays} rowKey={(record) => record.key} />
    },
  ];

  useEffect(() => {
    const fetchData = () => {
      customAxios.get(SERVER_URL + "/chat-monitor/usage?param=1").then(function (res) {
        setData24Hours(res.data);
        setLoading(false);
      });
    }

    fetchData();
  }, []);

  const onChange = async (key) => {
    setActiveKey(key);
    if (key === "2" && data7Days && data7Days.length === 0) {
      setLoading(true);
      const res = await customAxios.get(SERVER_URL + "/chat-monitor/usage?param=7")
      setData7Days(res.data);
      setLoading(false);
    } else if (key === "3" && data30Days && data30Days.length === 0) {
      setLoading(true);
      const res = await customAxios.get(SERVER_URL + "/chat-monitor/usage?param=30")
      setData30Days(res.data);
      setLoading(false);
    } else if (key === "4" && dataMTDDays && dataMTDDays.length === 0) {
      setLoading(true);
      const res = await customAxios.get(SERVER_URL + "/chat-monitor/usage?param=0")
      setDataMTDDays(res.data);
      setLoading(false);
    }
  };

  return (
    <div>
      {loading
       ?
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
        :
        <Tabs activeKey={activeKey} items={items} onChange={onChange} />
      }
    </div>
  )
};

export default UsageChatMonitor;
