import { Table, Button, Modal, Input, Form, Select, message, Space, Popconfirm } from "antd";
import React, { useState, useEffect, useMemo } from "react";
import customAxios from "../../utils/axios";
import axios from "axios";
import SelectInfluencerDropDown from "./SelectInfluencersDropDown";
import styles from "./styles.module.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, set } from 'date-fns';

import BarLoader from "react-spinners/BarLoader";


// export default DateRangeFilter;


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const { TextArea } = Input;
const formItemLayout = null;

const GroupUser = () => {
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [influencersData, setInfluencersData] = useState([]);
  const [selectedInfluencer, setSelectedInfluencer] = useState([]);
  const [areYouSureActive, setAreYouSureActive] = useState(false);

  const [fileInput, setFileInput] = useState(null);
  const [fileUploadPath, setFileUploadPath] = useState("");
  const [fileUploadLoading, setFileUploadLoading] = useState(false);

  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const userOptions = useMemo(
    () => userData.map((item) => ({ value: item.chat_id, label: item.chat_id })).filter((item) => item.value !== null),
    [userData]
  );

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    /*customAxios.get(SERVER_URL + "/user").then(function (res) {
      setUserData(res.data);
    });*/
    setLoading(false);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleUserChange = (value) => {
    setSelectedUser(value);
  };
   
 
  
  class ExportButton extends React.Component {
    downloadChatHistoryCSV = async () => {
      try {
        const startDateTime = startDate ? format(startDate, 'yyyy-MM-dd HH:mm:ss') : null;
        const endDateTime = endDate ? format(endDate, 'yyyy-MM-dd HH:mm:ss') : null;
        const encodedStartDateTime = encodeURI(startDateTime);
        const encodedEndDateTime = encodeURI(endDateTime);
        let inflName = "all"
        if (selectedInfluencer.length > 0){

        inflName = selectedInfluencer
        }
        let urls = `${SERVER_URL}/chat_history/csv/${inflName}`;

        /*if (startDateTime && endDateTime) {
          urls += `?start_time=${encodedStartDateTime}&end_time=${encodedEndDateTime}`;
        }*/

        const response = await customAxios.post(urls,
          {
           start_date: startDateTime,
           end_date: endDateTime,
           usersToExclude: selectedUser.join(","),
           influencers: selectedInfluencer.join(",")
          },
         {
           responseType: "blob" // Important to set this
         });
        
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "all_chat_histories.csv");
          document.body.appendChild(link);
          link.click();
      } catch (err) {
        console.error(err);
      }
    };

    downloadChatHistoryHumanReadableCSV = async () => {
      try {
        const startDateTime = startDate ? format(startDate, 'yyyy-MM-dd HH:mm:ss') : null;
        const endDateTime = endDate ? format(endDate, 'yyyy-MM-dd HH:mm:ss') : null;
        const encodedStartDateTime = encodeURI(startDateTime);
        const encodedEndDateTime = encodeURI(endDateTime);
        let urls = `${SERVER_URL}/chat_history_human_readable/csv/`;

        /*if (startDateTime && endDateTime) {
          urls += `?start_time=${encodedStartDateTime}&end_time=${encodedEndDateTime}`;
        }*/

    	  const response = await customAxios.post(urls,
           {
            start_date: startDateTime,
            end_date: endDateTime,
            usersToExclude: selectedUser.join(","),
            influencers: selectedInfluencer.join(",")
           },
          {
            responseType: "blob" // Important to set this
          });
        
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "all_chat_histories_human_readable.csv");
          document.body.appendChild(link);
          link.click();
      } catch (err) {
        console.error(err);
      }
    };

    downloadSpecialRequestsCSV = async () => {
      try {
        const startDateTime = startDate ? format(startDate, 'yyyy-MM-dd HH:mm:ss') : null;
        const endDateTime = endDate ? format(endDate, 'yyyy-MM-dd HH:mm:ss') : null;
        const encodedStartDateTime = encodeURI(startDateTime);
        const encodedEndDateTime = encodeURI(endDateTime);
        let inflName = "all"
        if (selectedInfluencer.length > 0){

        inflName = selectedInfluencer
        }
        let urls = `${SERVER_URL}/special_request_history/csv/${inflName}`;

        if (startDateTime && endDateTime) {
          urls += `?start_time=${encodedStartDateTime}&end_time=${encodedEndDateTime}`;
        }

    	  const response = await customAxios.get(urls,
          // ,{
          //   params:{
          //   start_date: encodedStartDateTime,
          //   end_date: encodedEndDateTime
          //   }
          // },
          {
            responseType: "blob" // Important to set this
          });
        
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "special_request_history.csv");
          document.body.appendChild(link);
          link.click();
      } catch (err) {
        console.error(err);
      }
    };

    downloadTipHistoryCSV = async () => {
      try {
        const startDateTime = startDate ? format(startDate, 'yyyy-MM-dd HH:mm:ss') : null;
        const endDateTime = endDate ? format(endDate, 'yyyy-MM-dd HH:mm:ss') : null;
        const encodedStartDateTime = encodeURI(startDateTime);
        const encodedEndDateTime = encodeURI(endDateTime);
        let inflName = "all"
        if (selectedInfluencer.length > 0){

        inflName = selectedInfluencer
        }
        let urls = `${SERVER_URL}/tip_history/csv/${inflName}`;

        if (startDateTime && endDateTime) {
          urls += `?start_time=${encodedStartDateTime}&end_time=${encodedEndDateTime}`;
        }

    	  const response = await customAxios.get(urls,
          // ,{
          //   params:{
          //   start_date: encodedStartDateTime,
          //   end_date: encodedEndDateTime
          //   }
          // },
          {
            responseType: "blob" // Important to set this
          });
        
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "tip_history.csv");
          document.body.appendChild(link);
          link.click();
      } catch (err) {
        console.error(err);
      }
    };

    downloadPaymentHistoryCSV = async () => {
      try {
        const startDateTime = startDate ? format(startDate, 'yyyy-MM-dd HH:mm:ss') : null;
        const endDateTime = endDate ? format(endDate, 'yyyy-MM-dd HH:mm:ss') : null;
        const encodedStartDateTime = encodeURI(startDateTime);
        const encodedEndDateTime = encodeURI(endDateTime);
        let inflName = "all"
        if (selectedInfluencer.length > 0){

        inflName = selectedInfluencer
        }
        let urls = `${SERVER_URL}/payment_history/csv/${inflName}`;

        if (startDateTime && endDateTime) {
          urls += `?start_time=${encodedStartDateTime}&end_time=${encodedEndDateTime}`;
        }

    	  const response = await customAxios.get(urls,
          // ,{
          //   params:{
          //   start_date: encodedStartDateTime,
          //   end_date: encodedEndDateTime
          //   }
          // },
          {
            responseType: "blob" // Important to set this
          });
        
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "payment_history.csv");
          document.body.appendChild(link);
          link.click();
      } catch (err) {
        console.error(err);
      }
    };

    downloadImageHistoryCSV = async () => {
      try {
        const startDateTime = startDate ? format(startDate, 'yyyy-MM-dd HH:mm:ss') : null;
        const endDateTime = endDate ? format(endDate, 'yyyy-MM-dd HH:mm:ss') : null;
        const encodedStartDateTime = encodeURI(startDateTime);
        const encodedEndDateTime = encodeURI(endDateTime);
        let inflName = "all"
        if (selectedInfluencer.length > 0){

        inflName = selectedInfluencer
        }
        let urls = `${SERVER_URL}/custom_image_requests_history/csv/${inflName}`;

        if (startDateTime && endDateTime) {
          urls += `?start_time=${encodedStartDateTime}&end_time=${encodedEndDateTime}`;
        }

    	  const response = await customAxios.get(urls,
          // ,{
          //   params:{
          //   start_date: encodedStartDateTime,
          //   end_date: encodedEndDateTime
          //   }
          // },
          {
            responseType: "blob" // Important to set this
          });
        
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image_request_history.csv");
          document.body.appendChild(link);
          link.click();
      } catch (err) {
        console.error(err);
      }
    }

    downloadErrorLogs = async () => {
      let url = `${SERVER_URL}/public/telegramBotLog.txt`;

      const response = await customAxios.get(url,
        {
          responseType: "blob" // Important to set this
        });
      
        const url2 = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url2;
        link.setAttribute("download", "error_logs.txt");
        document.body.appendChild(link);
        link.click();
    }
  
    render() {
      return (
        <>
        <Button style={{marginRight: "10px"}} type="primary" onClick={this.downloadChatHistoryCSV}>
          Download Chat History
        </Button>
        <Button style={{marginRight: "10px"}} type="primary" onClick={this.downloadChatHistoryHumanReadableCSV}>
          Download Chat History (Human Readable Format)
        </Button>
        <Button style={{marginRight: "10px"}} type="primary" onClick={this.downloadSpecialRequestsCSV}>
          Download Special Requests
        </Button>
        <Button style={{marginRight: "10px"}} type="primary" onClick={this.downloadTipHistoryCSV}>
          Download Tip History
        </Button>
        <Button style={{marginRight: "10px"}} type="primary" onClick={this.downloadPaymentHistoryCSV}>
          Download Payment History
        </Button>
        <Button style={{marginRight: "10px"}} type="primary" onClick={this.downloadImageHistoryCSV}>
          Download Image requests
        </Button>
        <Button style={{marginRight: "10px"}} type="primary" onClick={this.downloadErrorLogs}>
          Download Error Logs
        </Button>
        </>
      );
    }
  }

  class ConsumtionReport extends React.Component {
    downloadConsumptionReport = async () => {
      try {
        const startDateTime = startDate ? format(startDate, 'yyyy-MM-dd HH:mm:ss') : null;
        const endDateTime = endDate ? format(endDate, 'yyyy-MM-dd HH:mm:ss') : null;
        const encodedStartDateTime = encodeURI(startDateTime);
        const encodedEndDateTime = encodeURI(endDateTime);
        let inflName = "all"
        if (selectedInfluencer.length > 0){
          inflName = selectedInfluencer
        } else {
          alert("Please select an influencer")
          return;
        }
        let urls = `${SERVER_URL}/consumption_report/csv/${inflName}`;

        /*if (startDateTime && endDateTime) {
          urls += `?start_time=${encodedStartDateTime}&end_time=${encodedEndDateTime}`;
        }*/

        const response = await customAxios.post(urls,
          {
           start_date: startDateTime,
           end_date: endDateTime,
           usersToExclude: selectedUser.join(","),
           influencers: selectedInfluencer.join(",")
          },
         {
           responseType: "blob" // Important to set this
         });
        
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "consumption_report_"+inflName+".csv");
          document.body.appendChild(link);
          link.click();
      } catch (err) {
        console.error(err);
      }
    };

    render() {
      return (
        <>
        <Button style={{marginRight: "10px"}} type="primary" onClick={this.downloadConsumptionReport}>
          Download Consumption Report
        </Button>
        </>
      );
    }
  }

 /* const tableData = useMemo(() => {
    const arr = [];
  
    // Check if "all" is present in selectedInfluencer
    const isAllSelected = selectedInfluencer.includes("all");
  
    if (selectedInfluencer.length === 0 || isAllSelected) {
      // If selectedInfluencer is empty or "all" is selected, return all influencer data without any filter
      influencersData.forEach((influencer) => {
        const influencerName = influencer.name;
        arr.push({ ...conn, influencer_name: influencerName });
      });
    } else {
      // If specific influencers are selected, filter based on the selected influencers
      selectedInfluencer.forEach((selectedName) => {
        const influencer = influencersData.find((influencer) => influencer.name === selectedName);
        if (influencer) {
          const influencerName = influencer.name;
          arr.push({ ...conn, influencer_name: influencerName });
        }
      });
    }
  
    console.log("arr", arr);
    return arr;
  }, [influencersData, selectedInfluencer]);
  */
  

  const [messageApi, contextHolder] = message.useMessage();

  const influencersName = useMemo(() => influencersData.map((item) => item.name), [influencersData, selectedInfluencer]);
  
  const getNormalizedDateText = (txt) => {
    return txt?.toString()?.replace(" GMT", "") ?? "Alive";
  };
  const [isAddMsgModalOpen, setIsAddMsgModalOpen] = useState(false);
  const columns = [
    {
      title: "User",
      key: "username",
      dataIndex: "username",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Connected At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{getNormalizedDateText(text)}</div>
    },
    {
      title: "Stopped At",
      dataIndex: "stopped_at",
      key: "stopped_at",
      render: (text) => <div>{getNormalizedDateText(text)}</div>
    },
    {
      title: "Influencer",
      dataIndex: "influencer_name",
      key: "influencer_name",
      render: (text) => <div>{text}</div>
    }
  ];

  useEffect(() => {
    getInfluencerData();
    getUserData();
  }, []);

  const getInfluencerData = async () => {
    try {
      const { data } = await customAxios.get(SERVER_URL + "/getInfluencers");
      console.log("data", data)
      setInfluencersData(data);
    } catch (error) {
      console.log("--ERROR--", error);
    }
  };

  const showSendMsgModal = () => {
    setIsAddMsgModalOpen(true);
  };

  const handleMsgAreYouSure = () => {
    setAreYouSureActive(true);
  };

  const uploadImageHandler = async () => {
    if (!fileInput) {
      messageApi.error("Invalid file!");
      return;
    }
    try {
      setFileUploadLoading(true);

      // Create a new FormData instance
      const formData = new FormData();

      // Append the file to the FormData instance
      formData.append("file", fileInput);
      formData.append("name", fileInput.name);
      formData.append("type", fileInput.type);

      // POST the formData instance to the server
      let response = await customAxios.post(SERVER_URL + "/upload/url", formData, {
        headers: {
          "Content-Type": fileInput.type
        }
      });

      // The file upload is complete
      setFileUploadPath(response.data.file_path);
      messageApi.success("File upload success!");

    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message
      });
    } finally {
      setFileUploadLoading(false);
    }
  };

  const handleMsgOk = async () => {
    try {
              
      const formData = form.getFieldsValue();

      if(formData.message === " " || formData.message === undefined || formData.influencers === undefined || formData.influencers.length === 0){
        alert("Please enter a message and select influencer")
        return;
      }

      let toSendMessage = [];
      formData.influencers.forEach((influencer) => {
        let filteredInfluencer = influencersData.find((item) => item.name === influencer);
        console.log("influencer", filteredInfluencer);
        toSendMessage.push(filteredInfluencer.connections);
      });

      toSendMessage = toSendMessage.flat();

      const uniqueIds = new Set();

      // removing duplicacy
      toSendMessage = toSendMessage.filter((item) => {
        if (!item.chat_id) {
          return false;
        }
        const isDuplicate = uniqueIds.has(item.chat_id);
        uniqueIds.add(item.chat_id);
        if (!isDuplicate) {
          return true;
        }
        return false;
      });

      formData.append("user_ids", uniqueIds);
      formData.append("image_path", fileUploadPath);

      // POST the formData instance to the server
      let response = await customAxios.post(SERVER_URL + "/system/sendMessages", formData, {
      });

      if (response.data.success) {
        messageApi.success("Message sent successfully!");
      } else {
        messageApi.error("Message sending failed!");
      }


      /*const url = process.env.REACT_APP_SEND_MESSGE_URL; //https://api.telegram.org/bot

      customAxios.get(SERVER_URL + "/system/settings").then(function (res) {
          
          let TELEGRAM_BOT_TOKEN = res.data[0].telegram_bot_token;

          toSendMessage.forEach((item) => {
            axios.get(url + TELEGRAM_BOT_TOKEN + `/sendMessage?chat_id=${item.chat_id}&text=${formData.message}`);
          });

        });*/

    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message
      });
    } finally {
      setAreYouSureActive(false);
      // Wait for 0.5 second before closing the modal
      setTimeout(() => {
        setIsAddMsgModalOpen(false);
      }, 500);
    }
  };

  const handleMsgCancel = () => {
    setAreYouSureActive(false);
    setIsAddMsgModalOpen(false);
  };

  return (
    <div>
      {contextHolder}
      <div>
        {/*<Button type="primary" style={{ margin: "0 8px" }} onClick={() => showSendMsgModal()}>
          Send message
        </Button>*/}
        <SelectInfluencerDropDown
          options={influencersName}
          selectedInfluencer={selectedInfluencer}
          setSelectedInfluencer={setSelectedInfluencer}
        />
      </div>
      <br />
      <div>
        <Select
          mode="tags"
          size={"middle"}
          placeholder="Please select users to exclude"
          style={{
            width: "100%"
          }}
          options={userOptions}
          onChange={handleUserChange}
        />
      </div>
      <div>
        <DatePicker
          selected={startDate}
          onChange={handleStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
          showTimeInput
          dateFormat="yyyy-MM-dd HH:mm:ss"
          timeInputLabel="Time:"
        />
        <DatePicker
          selected={endDate}
          onChange={handleEndDateChange}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="End Date"
          showTimeInput
          dateFormat="yyyy-MM-dd HH:mm:ss"
          timeInputLabel="Time:"
        />
      </div>
<div>
  <ExportButton></ExportButton>
  <ConsumtionReport></ConsumtionReport>
</div>
      <div>
      {loading &&
          <BarLoader color="#1677ff" 
            cssOverride={{
              display: "block",
              margin: "10vh auto",
              borderColor: "red",
            }}
            size={150}
          />
        }
        {/*!loading &&
          <Table style={{ marginTop: "8px" }} columns={columns} dataSource={tableData} rowKey="id" />*/
        }
      </div>


      <Modal title="Send message to influencer's users" open={isAddMsgModalOpen} onOk={handleMsgAreYouSure} onCancel={handleMsgCancel}>
        <Form form={form} name="dynamic_rule" layout="vertical">
          <Form.Item {...formItemLayout} name="influencers" label="Select Influencer">
            <Select
              mode="tags"
              size={"middle"}
              placeholder="Please select users"
              style={{
                width: "100%"
              }}
              options={influencersName.map((item) => ({ value: item, label: item }))}
            />
          </Form.Item>
          <Space.Compact block>
                    <Input
                      type="file"
                      placeholder="Select file"
                      accept="image/*"
                      style={{ width: "80%" }}
                      onChange={(e) => {
                        if (e.target.files && e.target.files.length > 0) {
                          setFileInput(e.target.files[0]);
                        }
                      }}
                    />
                      <Button
                        type="primary"
                        onClick={uploadImageHandler}
                        style={{ width: "20%", marginTop: "0px" }}
                        loading={fileUploadLoading}
                        disabled={fileUploadPath}
                        >
                        {fileUploadPath.length > 0 ? "Uploaded" : "Upload"}
                      </Button>
          </Space.Compact>
          <Form.Item {...formItemLayout} name="message" label="Message">
            <TextArea rows={4} />
          </Form.Item>
          
          <div style={{position: "absolute", right: "3vw", bottom:"3vh"}}>
            <Popconfirm open={areYouSureActive} title="Are you sure？" onConfirm={() => handleMsgOk()} onCancel={() => handleMsgCancel()}  okText="Send" cancelText="Cancel"></Popconfirm>
          </div>

        </Form>
      </Modal>

    </div>
  );
};

export default GroupUser;

// const Dropdown = (props) => {
//   const options = ["24 hours", "48 hours", "72 hours", "1 week", "2 weeks", "1 month", "3 months", "6 months"];

//   const [selectedOption, setSelectedOption] = useState("");

//   const handleSelect = (e) => {
//     setSelectedOption(e.target.value);
//   };

//   return (
//     <div style={{ display: "flex", alignItems: "center" }}>
//       <label htmlFor="duration" style={{ marginRight: "0.5rem", color: "#333" }}>
//         Duration:
//       </label>
//       <select
//         id="duration"
//         value={selectedOption}
//         onChange={handleSelect}
//         style={{
//           padding: "0.5rem",
//           fontSize: "1rem",
//           borderRadius: "4px",
//           border: "1px solid #ccc",
//           backgroundColor: "#f7f7f7",
//           color: "#333"
//         }}
//       >
//         <option value="">Select an option</option>
//         {options.map((option) => (
//           <option key={option} value={option} style={{ fontSize: "1rem" }}>
//             {option}
//           </option>
//         ))}
//       </select>
//       {/* {selectedOption && (
//         <p style={{ marginTop: '1rem', fontSize: '1rem', color: '#666' }}>
//           Duration: {selectedOption}
//         </p>
//       )} */}
//     </div>
//   );
// };
