import React, { useState, useEffect } from "react";
import { Button, Input } from "antd";
import axios from "axios";
import { SERVER_URL } from "../../Constants";

const InappropriateWords = () => {
    const [words, setWords] = useState([]);
    const [newWord, setNewWord] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const token = localStorage.getItem("accessToken");

    const fetchWords = async () => {
        try {
            const { data } = await axios.get(SERVER_URL + "/inappropriate_words", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setWords(data || []);
        } catch (error) {
            console.error("Error fetching words:", error);
        }
    };

    useEffect(() => {
        fetchWords();
    }, [token]);

    const addWord = async () => {
        if (newWord && !words.includes(newWord)) {
            try {
                await axios.post(
                    SERVER_URL + "/inappropriate_words",
                    { word: newWord },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setWords([...words, { word: newWord }]);
                setNewWord("");
            } catch (error) {
                console.error("Error adding word:", error);
            } finally {
                fetchWords();
            }
        }
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const deleteWord = async (wordToDelete) => {
        try {
            const wordId = words.find((word) => word.word === wordToDelete).id;
            await axios.delete(SERVER_URL + `/inappropriate_words/${wordId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setWords(words.filter((word) => word.word !== wordToDelete));
        } catch (error) {
            console.error("Error deleting word:", error);
        }
    };

    const filteredWords =
        words?.filter((record) =>
            record.word.toLowerCase().includes(searchTerm.toLowerCase())
        ) || [];

    return (
        <div>
            <h2>Inappropriate Words</h2>
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", marginBottom: "50px" }}>
                <Input
                    type="text"
                    value={newWord}
                    onChange={(e) => setNewWord(e.target.value)}
                    placeholder="Add a new word"
                    style={{ width: "50%", height: "30px" }}
                    min={1}
                    onKeyDown={(e) => {
                        console.log(e.key);
                        if (e.key === 'Enter') {
                            addWord();
                        }
                    }}
                />
                <Button
                    onClick={addWord}
                    style={{ backgroundColor: "#1677ff", color: "white", height: "30px", marginTop: 0 }}
                >
                    Add Word
                </Button>
            </div>
            <h3>Search Words:</h3>
            <Input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Search words"
                style={{ width: "50%", height: "30px" }}
            />
            <ul>
                {filteredWords.map((word, index) => (
                    <li key={index}>
                        {word.word}
                        <Button
                            type="primary"
                            onClick={() => deleteWord(word.word)}
                            size="small"
                            style={{ marginLeft: "20px" }}
                            danger
                        >
                            Delete
                        </Button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default InappropriateWords;
