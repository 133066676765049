import React, { useEffect, useState } from "react";
import customAxios from "../../utils/axios";
import { SERVER_URL, V2_API_KEY, V2_SERVER_URL } from "../../Constants";
import BarLoader from "react-spinners/BarLoader";
import { Button, Flex, Table, Tabs, Tag, notification } from "antd";
import ModifyInfluencersModal from "../../Components/ModifyInfluencersModal";
import axios from "axios";

const Loader = () => (
  <BarLoader
    color="#1677ff"
    cssOverride={{
      display: "block",
      margin: "10vh auto",
      borderColor: "red",
    }}
    size={150}
  />
);

const AgencySettings = () => {
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState(null);
  const [archivedAgencies, setArchivedAgencies] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState(null);

  const openNotification = (type = "info", description = '') => {
    api[type]({
      message: `Sucсess`,
      description,
      placement: 'bottom',
      duration: 3
    });
  };


  const showModal = (agency) => {
    setSelectedAgency(agency);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchData = () => {
    setIsLoading(true);
    customAxios
      .get(SERVER_URL + "/agency-influencers")
      .then(function (res) {
        const { data } = res;
        const agencies = data.agencies;

        const activeAgencies = agencies.filter(
          (agency) => agency.deleted_at === null
        );
        const deletedAgencies = agencies.filter(
          (agency) => agency.deleted_at !== null
        );

        setData(activeAgencies);
        setArchivedAgencies(deletedAgencies);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteAgency = async (agency) => {
    setIsDeleting(true);
    try {
      await axios.delete(V2_SERVER_URL + `/api/v2/agency/` + agency.uuid, {
        headers: {
          "X-API-KEY": V2_API_KEY,
        },
      });
      fetchData();
      openNotification("success", `Agency ${agency.label} successfully archived`)
    } catch (error) {
      console.error(error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleUndeleteAgency = async (agency) => {
    setIsDeleting(true);
    try {
      await axios.put(
        V2_SERVER_URL + `/api/v2/agency/` + agency.uuid + "/undelete",
        {},
        {
          headers: {
            "X-API-KEY": V2_API_KEY,
          },
        }
      );
      fetchData();
      openNotification("success", `Agency ${agency.label} successfully unarchived`)
    } catch (error) {
      console.error(error);
    } finally {
      setIsDeleting(false);
    }
  };

  const columns = [
    {
      title: "Agency Name",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "UUID",
      dataIndex: "uuid",
      key: "uuid",
    },
    {
      title: "Influencers",
      dataIndex: "influencers",
      key: "influencers",
      render: (influencers) =>
        influencers.length > 0 ? (
          influencers.map((inf) => (
            <Tag key={inf.uuid}>
              {inf.name} (ID: {inf.uuid})
            </Tag>
          ))
        ) : (
          <span>No influencers</span>
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, agency) => (
        <Flex gap="middle" wrap>
          <Button style={{ margin: 0 }} onClick={() => showModal(agency)}>
            Modify Influencers
          </Button>
          {agency.deleted_at ? (
            <Button
              primary
              type="primary"
              disabled={isDeleting}
              style={{ margin: 0 }}
              onClick={() => handleUndeleteAgency(agency)}
            >
              Undelete
            </Button>
          ) : (
            <Button
              danger
              type="primary"
              disabled={isDeleting}
              style={{ margin: 0 }}
              onClick={() => handleDeleteAgency(agency)}
            >
              Delete
            </Button>
          )}
        </Flex>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: "Active",
      children: isLoading ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          rowKey="value"
          pagination={false}
        />
      ),
    },
    {
      key: "2",
      label: "Archived",
      children: isLoading ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          dataSource={archivedAgencies}
          rowKey="value"
          pagination={false}
        />
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <ModifyInfluencersModal
        agency={selectedAgency}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        refetch={fetchData}
      />
      <div style={{ padding: 20 }}>
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </>
  );
};

export default AgencySettings;
