import { Button, Layout, Menu } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import ProtectedRoute from "../ProtectedRoute";
import axios from "../../utils/axios";
import customAxios from "../../utils/axios";

// const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const routesOrder = {
  "/": "1",
  "/reports": "2",
  "/influencer": "3",
  "/pricing": "4",
};

const Navigation = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  // console.log("navigate", location);

  const Navigation = (e) => {
    // console.log(e.key);
    switch (parseInt(e.key)) {
      case 1:
        navigate("/");
        break;
      case 2:
        navigate("/reports");
        break;
      case 3:
        navigate("/influencer");
        break;
      case 4:
        navigate("/pricing");
        break;
      case 5:
        navigate("/system_messages");
        break;
      case 6:
        navigate("/settings");
        break;
      case 7:
        navigate("/promo_codes");
        break;
      case 8:
        navigate("/tags");
        break;
      case 9:
        navigate("/posts");
        break;
      case 10:
        navigate("/shares");
        break;
      case 11:
        navigate("/chat-starter");
        break;
      case 12:
        navigate("/chat-monitor/messages");
        break;
      case 13:
        navigate("/chat-monitor/usage");
        break;
      case 14:
        navigate("/influencer/notifications");
        break;
      case 15:
        navigate("/referral-codes");
        break;
      case 16:
        navigate("/user-influencers");
        break;
      case 17:
        navigate("/settings/price");
        break;
      case 18:
        navigate("/influencer/cloudflare");
        break;
      case 19:
        navigate("/settings/inappropriate");
        break;
      case 20:
        navigate("/settings/global-notifications");
        break;
      case 21:
        navigate("/agency-settings");
        break;
      case 22:
        navigate("/agency-settings/default-models");
        break;
      default:
    }
  };

  const getMsgData = () => {
    customAxios.get(SERVER_URL + "/system/settings").then(function (res) {
      if (res && res.data && res.data.length > 0) {
        setData(res.data[0]);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getMsgData();
  }, []);

  function logoutHandler() {
    localStorage.clear();
    axios.defaults.headers.common["Authorization"] = "";
    navigate("/login");
  }

  return (
    <ProtectedRoute>
      <Layout>
        <Header
          className="header-container"
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
          }}
        >
          <Button
            type="primary"
            onClick={logoutHandler}
            danger
            style={{ margin: 0 }}
            // icon={<LogoutOutlined size={100} style={{ color: "red", cursor: "pointer" }} />}
          >
            Logout
          </Button>
          {!loading && (
            <div style={{ color: "white", display: "inherit" }}>
              <h4 style={{ margin: "10px" }}>{data["telegram_bot_name"]}</h4>
              <h1 style={{ margin: "10px" }}>{data["instance_name"]}</h1>
            </div>
          )}
        </Header>
        <Layout>
          <Sider width={200}>
            <Menu
              onClick={(e) => Navigation(e)}
              mode="inline"
              selectedKeys={[routesOrder[location.pathname]]}
              openKeys={["sub0", "sub1", "sub2", "sub3", "sub4", "sub5"]}
              style={{ height: "100%", borderRight: 0 }}
              items={[
                {
                  label: "All users",
                  key: "sub0",
                  children: [
                    {
                      key: "1",
                      label: "All Users",
                    },
                    {
                      key: "16",
                      label: "Dashboard Permissions",
                    },
                    {
                      key: "15",
                      label: "Referral Codes",
                    },
                  ],
                },
                {
                  label: "Agency",
                  key: "sub5",
                  children: [
                    {
                      key: "21",
                      label: "Agency Settings",
                    },
                    {
                      key: "22",
                      label: "Agency Default Models",
                    },
                  ],
                },
                {
                  label: "Influencer",
                  key: "sub1",
                  children: [
                    {
                      key: "3",
                      label: "All Influencers",
                    },
                    {
                      key: "18",
                      label: "Influencer cloudflare",
                    },
                    {
                      key: "14",
                      label: "Influencer notifications",
                    },
                    {
                      key: "10",
                      label: "Sharing Links",
                    },
                  ],
                },
                {
                  label: "Chat",
                  key: "sub2",
                  children: [
                    {
                      key: "11",
                      label: "Convo Starters",
                    },
                  ],
                },

                {
                  key: "9",
                  label: "Posts and Pricing",
                },

                {
                  key: "4",
                  label: "Pricing",
                },
                {
                  key: "7",
                  label: "Promo Codes",
                },
                {
                  key: "8",
                  label: "Tags",
                },
                {
                  label: "Global Settings",
                  key: "sub4",
                  children: [
                    {
                      key: "6",
                      label: "System Settings",
                    },
                    {
                      key: "17",
                      label: "Price Settings",
                    },
                    {
                      key: "19",
                      label: "Inappropriate Words Settings",
                    },
                    {
                      key: "20",
                      label: "Global Notifications",
                    },
                  ],
                },
                {
                  key: "5",
                  label: "System Messages",
                },
                {
                  label: "Chat Monitor",
                  key: "sub3",
                  children: [
                    {
                      key: "12",
                      label: "Response Times",
                    },
                    {
                      key: "2",
                      label: "Reports",
                    },
                    {
                      key: "13",
                      label: "Usage Summary",
                    },
                  ],
                },
              ]}
            />
          </Sider>
          <Layout style={{ padding: "0 24px 24px" }}>
            <Content
              style={{
                background: "#fff",
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </ProtectedRoute>
  );
};

export default Navigation;
