import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import User from "../Components/User";
// import CustomLayout from "../Components/CustomLayout";
import Group from "../Components/Group";
import Influencer from "../Components/Influencers";
import Navigation from "../Components/Navigation";
import ErrorPage from "../Components/ErrorPage";
import InfluencerDetail from "../Pages/InfluencerDetail";
import InfluencerSettings from "../Pages/InfluencerSettings";
import Login from "../Components/Login";
import SystemMessages from "../Components/SystemMessages";
import Settings from "../Components/Settings";
import Pricing from "../Components/Pricing";
import PromoCodes from "../Components/PromoCodes";
import Tags from "../Components/Tags";
import Posts from "../Components/Posts/intex";
import Shares from "../Components/Shares";
import Starter from "../Pages/Starter";
import MessagesChatMonitor from "../Pages/ChatMonitor/Messages";
import UsageChatMonitor from "../Pages/ChatMonitor/Usage";
import InfluencerNotifications from "../Pages/InfluencerNotifications";
import ReferralCodes from "../Pages/ReferralCodes";
import UserInfluencer from "../Pages/UserInfluencer";
import SettingsPrice from "../Pages/SettingsPrice";
import InfluencerCloudflare from "../Pages/InfluencerCloudflare";
import Reboot from "../Pages/Reboot";
import InappropriateWords from "../Components/InappropriateWords";
import SettingsGlobalNotifications from "../Pages/SettingsGlobalNotifications";
import AgencySettings from "../Pages/AgencySettings";
import AgencyDefaultModels from "../Pages/AgencyDefaultModels";

const Router = () => {
  const router = createBrowserRouter([
    {
      path: "/reboot",
      element: <Reboot />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/login",
      element: <Login />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/",
      element: <Navigation />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <User />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/influencer",
          element: <Influencer />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/influencer/:id",
          element: <InfluencerDetail />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/influencerSettings/:id",
          element: <InfluencerSettings />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/reports",
          element: <Group />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/promo_codes/",
          element: <PromoCodes />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/posts",
          element: <Posts />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/pricing",
          element: <Pricing />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/system_messages",
          element: <SystemMessages />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/settings",
          element: <Settings />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/shares",
          element: <Shares />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/tags",
          element: <Tags />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/chat-starter",
          element: <Starter />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/chat-monitor/messages",
          element: <MessagesChatMonitor />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/chat-monitor/usage",
          element: <UsageChatMonitor />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/influencer/notifications",
          element: <InfluencerNotifications />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/referral-codes",
          element: <ReferralCodes />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/user-influencers",
          element: <UserInfluencer />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/settings/price",
          element: <SettingsPrice />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/influencer/cloudflare",
          element: <InfluencerCloudflare />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/settings/inappropriate",
          // TODO: in developing
          element: <InappropriateWords />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/settings/global-notifications",
          // TODO: in developing
          element: <SettingsGlobalNotifications />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/agency-settings",
          element: <AgencySettings />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/agency-settings/default-models",
          element: <AgencyDefaultModels />,
          errorElement: <ErrorPage />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Router;
