import React, {useEffect, useState} from "react";
import {Button, Form, Input, message, Modal, Select, Table} from "antd";
import BarLoader from "react-spinners/BarLoader";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import axios from "../../utils/axios";
import {SERVER_URL} from "../../Constants";


const formItemLayout = null;
const { TextArea } = Input;

const Shares = () => {
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [influencerOptions, setInfluencerOptions] = useState([]);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [data, setData] = useState([
    {
      id: "",
      influencer_id: "",
      influencer_name: "",
      media_type: "",
      description: "",
    }
  ]);
  const [socialMediaOptions] = useState([
    {label: "Twitter", value: "twitter"},
    {label: "Discord", value: "discord"},
    {label: "Reddit", value: "reddit"},
  ]);

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Social Media",
      dataIndex: "media_type",
      key: "media_type",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Influencer",
      dataIndex: "influencer_name",
      key: "influencer_name",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <div style={{display: "flex", flexWrap: "wrap", gap: "0.5rem"}}>
          <Button
            type="primary"
            danger
            onClick={(event) => {editDetail(event, record)}}
            title={"Edit"}
          >
            <EditOutlined />
          </Button>
          <Button
            type="primary"
            danger
            onClick={(event) => {
              event.stopPropagation();
              handleOpenDeleteModal(record.id)
            }}
            title={"Delete"}
          >
            <DeleteOutlined />
          </Button>
        </div>
      )
    }
  ];

  useEffect(() => {
    const fetchData = () => {
      axios.get(SERVER_URL + "/shares").then(function (res) {
        const {shares, influencers} = res.data;
        setData(shares);
        if (influencers.length > 0) {
          setInfluencerOptions(influencers.map((item) => {
            return {
              label: item.name,
              value: item.id
            }
          }))
        }
        setLoading(false);
      });
    };
    fetchData();
  }, []);

  const openAddModal = () => {
    setIsOpenAddModal(true);
  };
  const closeAddModal = () => {
    setIsOpenAddModal(false);
    setIsEdit(false);
    setIsSubmit(false);
    form.resetFields();
  };
  const handleOk = async () => {
    try {
      setIsSubmit(true);
      await form.validateFields().then(async (values) => {
        // Create a new FormData instance
        const formData = new FormData();
        formData.append("influencer_id", values["influencer_id"]);
        formData.append("media_type", values["media_type"]);
        formData.append("description", values["description"] ? values["description"] : "");

        if (isEdit) {
          formData.append("share_id", values.id);
          const { data } = await axios.put(SERVER_URL + "/shares", formData);
          setData(data);
        } else {
          const { data } = await axios.post(SERVER_URL + "/shares", formData);
          setData(data);
        }

        messageApi.open({
          type: "success",
          content: "Share saved successfully!"
        });
        closeAddModal();
      });
    } catch (error) {
      console.log("--ERROR--", error);
      if (error.response) {
        // Try to get the error message from the server otherwise set it to a default message
        const errorMessage = error.response.data.error || "Something went wrong!";

        messageApi.open({
          type: "error",
          content: errorMessage
        });
      }
      setIsSubmit(false);
    }
  }

  function editDetail(event, record) {
    event.stopPropagation();
    setIsEdit(true);
    form.setFieldsValue({
      id: record.id,
      influencer_id: record.influencer_id,
      influencer_name: record.influencer_name,
      media_type: record.media_type,
      description: record.description,
    });
    openAddModal();
  }

  const handleOpenDeleteModal = (id) => {
    setIsDeleteModalOpen(true);
    setDeleteId(id);
  }
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeleteId("");
  }
  const deleteRecord = async () => {
    try {
      const { data } = await axios.delete(SERVER_URL + "/shares", {
        data: {
          id: deleteId
        }
      });
      setData(data);

      messageApi.open({
        type: "success",
        content: "Share deleted!"
      });
      handleCloseDeleteModal();
    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message
      });
    }
  };

  return (
    <div>
      {contextHolder}
      <div>
        <Button
          type="primary"
          onClick={() => openAddModal()}
        >
          Add Share
        </Button>
      </div>
      <div>
        {loading &&
          <BarLoader color="#1677ff"
            cssOverride={{
              display: "block",
              margin: "10vh auto",
              borderColor: "red",
            }}
            size={150}
          />
        }
        {!loading &&
          <Table style={{marginTop: "8px"}} columns={columns} dataSource={data} rowKey="id"/>
        }
      </div>

      <Modal
        title={isEdit ? "Edit Share" : "Add Share"}
        okText={isSubmit ? "Submitting..." : "OK"}
        open={isOpenAddModal}
        onOk={handleOk}
        onCancel={closeAddModal}
        width={640}
        okButtonProps={{ disabled:  isSubmit  }}
        cancelButtonProps={{ disabled:  isSubmit  }}
      >
        <Form
          form={form} name="dynamic_rule" layout="vertical"
        >
          <div style={{display: "flex", gap: "10px"}}>
            <div style={{width: "100%"}}>
              <Form.Item
                {...formItemLayout}
                name="influencer_id"
                label="Influencer"
                rules={[{required: true, message: "Please select influencer"}]}
              >
                <Select
                  name="influencer_id"
                  options={influencerOptions}
                  rules={[{required: true, message: "Please select influencer"}]}
                />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="media_type"
                label="Social Media"
                rules={[{required: true, message: "Please select social media"}]}
              >
                <Select
                  name="influencer_id"
                  options={socialMediaOptions}
                  rules={[{required: true, message: "Please select social media"}]}
                />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="description"
                label="Description"
                rules={[{required: true, message: "Please add description"}]}
              >
                <TextArea rows={3} placeholder="Please input description"/>
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="id"
              >
                <Input type="hidden" placeholder=""/>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        title={"Delete share"}
        open={isDeleteModalOpen}
        onOk={deleteRecord}
        onCancel={handleCloseDeleteModal}
      >
        <div>Delete Share?</div>
      </Modal>

    </div>
  )
};

export default Shares;
