import React, { useEffect, useState } from "react";
import { Radio, Input, DatePicker, Button, Flex } from "antd";
import customAxios from "../../utils/axios";
import { SERVER_URL } from "../../Constants";
import BarLoader from "react-spinners/BarLoader";
import moment from "moment";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const SettingsGlobalNotifications = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  const [mode, setMode] = useState("disabled");
  const [reminderText, setReminderText] = useState("");
  const [maintenanceDates, setMaintenanceDates] = useState(null);

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  useEffect(() => {
    switch (mode) {
      case "disabled":
        setIsSaveButtonDisabled(false);
        break;
      case "reminder":
        if (reminderText) {
          setIsSaveButtonDisabled(false);
        } else setIsSaveButtonDisabled(true);
        break;
      case "maintenance":
        if (reminderText && maintenanceDates) {
          setIsSaveButtonDisabled(false);
        } else setIsSaveButtonDisabled(true);
        break;
      default:
        setIsSaveButtonDisabled(true);
    }
  }, [mode, reminderText, maintenanceDates]);

  useEffect(() => {
    const fetchData = () => {
      customAxios
        .get(SERVER_URL + "/settings/global-notifications")
        .then(function (res) {
          const { data } = res;
          console.log(data);
          setMode(data.global_notification_mode);
          setReminderText(data.global_notification_message)

          if (data.global_notification_mode === "maintenance") {
            setMaintenanceDates([
              moment(data.maintenance_from),  // Convert start date to moment
              moment(data.maintenance_to),    // Convert end date to moment
            ]);
          }

          setIsLoading(false);
        });
    };
    fetchData();
  }, []);

  const onModeChange = (e) => {
    setReminderText(null)
    setMaintenanceDates(null)
    setMode(e.target.value);
  };

  const onReminderTextChange = (e) => {
    setReminderText(e.target.value);
  };

  const onDatesChange = (dates, dateStrings) => {
    setMaintenanceDates(dates);
  };

  const handleSave = async () => {
    setIsUpdating(true);
    try {
      const data = {
        mode,
        reminderText: mode === "reminder" || mode === "maintenance" ? reminderText : null,
        maintenanceFrom: mode === "maintenance" ? maintenanceDates[0].$d : null,
        maintenanceTo: mode === "maintenance" ? maintenanceDates[1].$d : null,
      };
      await customAxios.put(SERVER_URL + "/settings/global-notifications", data)
    } catch (error) {
      console.error(error);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div style={{ padding: 20, display: "flex", justifyContent: "center" }}>
      {isLoading ? (
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      ) : (
        <div
          style={{
            maxWidth: "600px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Radio.Group onChange={onModeChange} value={mode}>
            <Radio value="disabled">Disabled</Radio>
            <Radio value="reminder">Reminder</Radio>
            <Radio value="maintenance">Maintenance mode</Radio>
          </Radio.Group>

          <Flex
            vertical
            align={"flex-end"}
            style={{ marginTop: 20, width: "100%" }}
          >
            {mode === "reminder" && (
              <Flex vertical style={{ width: "100%" }}>
                <label>Reminder</label>
                <TextArea
                  rows={4}
                  value={reminderText}
                  onChange={onReminderTextChange}
                  placeholder="Reminder text"
                />
              </Flex>
            )}

            {mode === "maintenance" && (
              <Flex vertical style={{ width: "100%" }}>
                <label>Maintenance mode</label>
                <TextArea
                  rows={4}
                  value={reminderText}
                  onChange={onReminderTextChange}
                  placeholder="Reminder text"
                />
                <RangePicker
                  showTime
                  style={{ marginTop: 10 }}
                  onChange={onDatesChange}
                  format="YYYY-MM-DD HH:mm:ss"
                  value={maintenanceDates}
                />
              </Flex>
            )}

            <div>
              <Button
                type="primary"
                onClick={handleSave}
                style={{ marginTop: 20 }}
                disabled={isSaveButtonDisabled}
                loading={isUpdating}
              >
                Save
              </Button>
            </div>
          </Flex>
        </div>
      )}
    </div>
  );
};

export default SettingsGlobalNotifications;
