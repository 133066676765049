import {Form} from "antd";
import React from "react";

const BasicFunctionalities = (formItemLayout) => {
  return (
    <>
      <h2>Basic Functionalities</h2>
      <Form.Item
        {...formItemLayout}
        name="visible"
        label="Visible"
      >
        <select name="visible" id="visible" style={{width: "100%"}}>
          <option value="1">True</option>
          <option value="0">False</option>
        </select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="coming_soon_mode"
        label="Coming Soon Mode"
      >
        <select name="coming_soon_mode" id="coming_soon_mode" style={{width: "100%"}}>
          <option value="1">True</option>
          <option value="0">False</option>
        </select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="coming_soon_msg"
        label="Coming Soon Message"
      >
        <input type="text" name="coming_soon_msg" id="coming_soon_msg" style={{width: "100%"}}/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="allowImageSending"
        label="Allow Image Sending"
      >
        <select name="allowImageSending" id="allowImageSending" style={{width: "100%"}}>
          <option value="1">True</option>
          <option value="0">False</option>
        </select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="allowLinkSending"
        label="Allow Link Sending"
      >
        <select name="allowLinkSending" id="allowLinkSending" style={{width: "100%"}}>
          <option value="1">True</option>
          <option value="0">False</option>
        </select>
      </Form.Item>
    </>
  )
}

export default BasicFunctionalities;
