import React, { useEffect, useMemo, useState } from "react";
import { Button, Flex, List, Modal, Select, Space } from "antd";
import customAxios from "../utils/axios";
import { SERVER_URL } from "../Constants";

const ModifyInfluencersModal = (props) => {
  const { agency, isModalOpen, handleOk, handleCancel, refetch } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSelect, setIsLoadingSelect] = useState(true);

  const [influencerOptions, setInfluencerOptions] = useState([]);

  const [influencers, setInfluencers] = useState([]);

  const fetchInfluencersForSelect = () => {
    setIsLoadingSelect(true);
    customAxios
      .get(SERVER_URL + "/influencers-short")
      .then(function (res) {
        const { data } = res;
        setInfluencerOptions(data);
      })
      .finally(() => {
        setIsLoadingSelect(false);
      });
  };

  useEffect(() => {
    fetchInfluencersForSelect();
  }, []);

  useEffect(() => {
    if (agency) {
      setInfluencers(agency.influencers);
    }
  }, [agency]);

  const influencersList = useMemo(() => {
    if (influencers.length === 0) return <div>No influencers added yet</div>;
    return (
      <List
        dataSource={influencers}
        renderItem={(influencer) => (
          <List.Item
            style={{ display: "flex" }}
            actions={[
              <Button
                onClick={() => handleDeleteInfluencers(influencer)}
                danger
              >
                Delete
              </Button>,
            ]}
          >
            {influencer.name} (ID: {influencer.uuid})
          </List.Item>
        )}
      />
    );
  }, [influencers]);

  const handleDeleteInfluencers = (influencerToDelete) => {
    setInfluencers((prevInfluencers) =>
      prevInfluencers.filter(
        (influencer) => influencer.uuid !== influencerToDelete.uuid
      )
    );
  };

  const onSelectInfluencer = (value) => {
    const influencer = influencerOptions.find((item) => item.id === value);

    if (
      influencer &&
      !influencers.some((item) => item.uuid === influencer.id)
    ) {
      setInfluencers((prevState) => [
        ...prevState,
        { name: influencer.label, uuid: influencer.id },
      ]);
    }
  };


  const handleSave = async () => {
    try {
      setIsLoading(true);
      await customAxios.put(SERVER_URL + "/agency-influencers", {
        data: influencers,
        agency_id: agency.id,
      });
      handleOk();
      refetch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={agency?.label}
      open={isModalOpen}
      onOk={handleSave}
      onCancel={handleCancel}
      confirmLoading={isLoading}
    >
      <Space style={{ width: "100%" }} direction="vertical">
        {influencersList}
        <Flex style={{ width: "100%" }}>
          <Select
            onChange={onSelectInfluencer}
            style={{ width: "100%" }}
            name="influencer_id"
            options={influencerOptions.map((option) => {
              return { value: option.id, label: option.label };
            })}
            loading={isLoadingSelect}
            disabled={isLoadingSelect}
          />
        </Flex>
      </Space>
    </Modal>
  );
};

export default ModifyInfluencersModal;
