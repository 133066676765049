import { Button, Flex, notification, Table } from "antd";
import customAxios from "../../utils/axios";
import { SERVER_URL } from "../../Constants";
import React, { useEffect, useState } from "react";
import BarLoader from "react-spinners/BarLoader";

const Loader = () => (
  <BarLoader
    color="#1677ff"
    cssOverride={{
      display: "block",
      margin: "10vh auto",
      borderColor: "red",
    }}
    size={150}
  />
);

const AgencyDefaultModels = () => {
  const [api, contextHolder] = notification.useNotification();

  const [defaultInfluencers, setDefaultInfluencers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  console.log(defaultInfluencers)

  const openNotification = (type = "info", description = "") => {
    api[type]({
      message: `Sucсess`,
      description,
      placement: "bottom",
      duration: 3,
    });
  };

  const fetchData = () => {
    customAxios
      .get(SERVER_URL + "/influencers-default")
      .then(function (res) {
        const { data } = res;

        setDefaultInfluencers(data.influencers);
      })
      .finally(() => {
        setIsLoading(false);
        setIsUpdating(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleMarkInfluencerAsDefault = async (agency) => {
    try {
      setIsUpdating(true);
      await customAxios.put(SERVER_URL + "/influencers-default", {
        influencer_uuid: agency.uuid,
        is_default: 1,
      });
      fetchData();
      openNotification(
        "success",
        `Influencer ${agency.name} marked as default`
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleUnmarkInfluencerAsDefault = async (agency) => {
    try {
      setIsUpdating(true);
      await customAxios.put(SERVER_URL + "/influencers-default", {
        influencer_uuid: agency.uuid,
        is_default: 0,
      });

      fetchData();
      openNotification(
        "success",
        `Influencer ${agency.name} unmarked as default`
      );
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      title: "Influencer Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "UUID",
      dataIndex: "uuid",
      key: "uuid",
    },
    {
      title: "Action",
      key: "action",
      render: (_, agency) => (
        <Flex gap="middle" wrap>
          {agency.is_default ? (
            <Button
              danger
              primary
              type="primary"
              disabled={isUpdating}
              style={{ margin: 0 }}
              onClick={() => handleUnmarkInfluencerAsDefault(agency)}
            >
              Unmark as default
            </Button>
          ) : (
            <Button
              primary
              type="primary"
              disabled={isUpdating}
              style={{ margin: 0 }}
              onClick={() => handleMarkInfluencerAsDefault(agency)}
            >
              Mark as default
            </Button>
          )}
        </Flex>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <div style={{ padding: 20 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Table
            columns={columns}
            dataSource={defaultInfluencers}
            rowKey="value"
            pagination={false}
          />
        )}
      </div>
    </>
  );
};

export default AgencyDefaultModels;
