import React, { useEffect, useState } from "react";
import { Form, message, Table, Tabs } from "antd";
import customAxios from "../../utils/axios";
import { SERVER_URL } from "../../Constants";
import BarLoader from "react-spinners/BarLoader";

const ReferralCodes = () => {
  const [loading, setLoading] = useState(true);
  const [activeKey, setActiveKey] = useState("1");
  const [dataToday, setDataToday] = useState([]);
  const [dataYesterday, setDataYesterday] = useState([]);
  const [data7Days, setData7Days] = useState([]);
  const [data30Days, setData30Days] = useState([]);
  const [dataMTDDays, setDataMTDDays] = useState([]);
  const [data90Days, setData90Days] = useState([]);
  const [data365Days, setData365Days] = useState([]);

  // const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: "Referral Code",
      dataIndex: "referral_code",
      key: "referral_code",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Users Count",
      dataIndex: "count",
      key: "count",
      render: (text) => <div>{text}</div>,
    },
  ];

  const items = [
    {
      key: "1",
      label: "Today",
      children: (
        <Table
          key="idToday"
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={dataToday}
          rowKey={(record) => record.referral_code}
        />
      ),
    },
    {
      key: "2",
      label: "Yesterday",
      children: (
        <Table
          key="idYesterday"
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={dataYesterday}
          rowKey={(record) => record.referral_code}
        />
      ),
    },
    {
      key: "3",
      label: "Past 7 Days",
      children: (
        <Table
          key="id7Days"
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={data7Days}
          rowKey={(record) => record.referral_code}
        />
      ),
    },
    {
      key: "4",
      label: "Past 30 Days",
      children: (
        <Table
          key="id30Days"
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={data30Days}
          rowKey={(record) => record.referral_code}
        />
      ),
    },
    {
      key: "5",
      label: "Month to Date",
      children: (
        <Table
          key="idMTDDays"
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={dataMTDDays}
          rowKey={(record) => record.referral_code}
        />
      ),
    },
    {
      key: "6",
      label: "Last 90 days",
      children: (
        <Table
          key="id90Days"
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={data90Days}
          rowKey={(record) => record.referral_code}
        />
      ),
    },
    {
      key: "7",
      label: "Last year",
      children: (
        <Table
          key="id365Days"
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={data365Days}
          rowKey={(record) => record.referral_code}
        />
      ),
    },
  ];

  useEffect(() => {
    getReferralData();
  }, []);

  const getReferralData = () => {
    customAxios
      .get(SERVER_URL + "/referrals?interval=today")
      .then(function (res) {
        setDataToday(res.data);
        setLoading(false);
      });
  };

  const onChange = async (key) => {
    setActiveKey(key);
    if (key === "2" && dataYesterday && dataYesterday.length === 0) {
      setLoading(true);
      const res = await customAxios.get(
        SERVER_URL + "/referrals?interval=yesterday"
      );
      setDataYesterday(res.data);
      setLoading(false);
    } else if (key === "3" && data7Days && data7Days.length === 0) {
      setLoading(true);
      const res = await customAxios.get(
        SERVER_URL + "/referrals?interval=last_7_days"
      );
      setData7Days(res.data);
      setLoading(false);
    } else if (key === "4" && data30Days && data30Days.length === 0) {
      setLoading(true);
      const res = await customAxios.get(
        SERVER_URL + "/referrals?interval=last_30_days"
      );
      setData30Days(res.data);
      setLoading(false);
    } else if (key === "5" && dataMTDDays && dataMTDDays.length === 0) {
      setLoading(true);
      const res = await customAxios.get(
        SERVER_URL + "/referrals?interval=month_to_date"
      );
      setDataMTDDays(res.data);
      setLoading(false);
    } else if (key === "6" && data90Days && data90Days.length === 0) {
      setLoading(true);
      const res = await customAxios.get(
        SERVER_URL + "/referrals?interval=last_90_days"
      );
      setData90Days(res.data);
      setLoading(false);
    } else if (key === "7" && data365Days && data365Days.length === 0) {
      setLoading(true);
      const res = await customAxios.get(
        SERVER_URL + "/referrals?interval=last_365_days"
      );
      setData365Days(res.data);
      setLoading(false);
    }
  };

  return (
    <div>
      {contextHolder}

      {loading ? (
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      ) : (
        <Tabs activeKey={activeKey} items={items} onChange={onChange} />
      )}
    </div>
  );
};

export default ReferralCodes;
