import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Table,
  Image,
  Upload,
  Checkbox,
  Tabs,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import axios from "../../utils/axios";
import { SERVER_URL } from "../../Constants";
import BarLoader from "react-spinners/BarLoader";
import ReactPlayer from "react-player/file";
import { isImage } from "../../utils/utils";
import dayjs from "dayjs";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import DraggableUploadListItem from "../DraggableUploadListItem/intex";

const formItemLayout = null;
const { TextArea } = Input;
const dateFormat = "YYYY-MM-DD";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const Posts = () => {
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpenPostModal, setIsOpenPostModal] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const [bonusContentData, setBonusContentData] = useState([]);
  const [packagesData, setPackagesData] = useState([]);
  const [influencerOptions, setInfluencerOptions] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletePostId, setDeletePostId] = useState("");
  const [previewList, setPreviewList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isPackage, setIsPackage] = useState(false);
  const [isSkipWaitList, setIsSkipWaitList] = useState(false);
  const [isPined, setIsPined] = useState(false);
  const [isHumanGenerated, setIsHumanGenerated] = useState(false);

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Influencer",
      dataIndex: "influencer_name",
      key: "influencer_name",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => <div>${text}</div>,
    },
    {
      title: "Tokens",
      dataIndex: "tokens",
      key: "tokens",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (number) => <div>{number ? "Yes" : "No"}</div>,
    },
    {
      title: "Is Package",
      dataIndex: "is_package",
      key: "is_package",
      render: (number) => <div>{number ? "Yes" : "No"}</div>,
    },
    {
      title: "Is Pined",
      dataIndex: "is_pined",
      key: "is_pined",
      render: (number) => <div>{number ? "Yes" : "No"}</div>,
    },
    {
      title: "Human generated",
      dataIndex: "human_generated",
      key: "human_generated",
      render: (number) => <div>{number ? "Yes" : "No"}</div>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <div style={{ width: "75px" }}>{dayjs(text).format(dateFormat)}</div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Preview",
      dataIndex: "preview_url",
      key: "preview_url",
      render: (url) =>
        url ? (
          isImage(url) ? (
            <Image width={100} src={url} />
          ) : (
            <ReactPlayer width={100} url={url} />
          )
        ) : (
          <div>No preview</div>
        ),
    },
    {
      title: "Media",
      dataIndex: "media_urls",
      key: "media_urls",
      render: (urls) => {
        return (
          <>
            {urls.length > 0 &&
              (isImage(urls[0]["url"]) ? (
                <Image.PreviewGroup items={urls}>
                  <Image width={100} src={urls[0]["url"]} />
                </Image.PreviewGroup>
              ) : (
                <ReactPlayer width={100} url={urls[0]["url"]} />
              ))}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "0.5rem" }}>
          <Button
            type="primary"
            danger
            onClick={(event) => {
              editDetail(event, record);
            }}
            title={"Edit"}
          >
            <EditOutlined />
          </Button>
          <Button
            type="primary"
            danger
            onClick={(event) => {
              event.stopPropagation();
              handleOpenDeleteModal(record.id);
            }}
            title={"Delete"}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: "Bonus Content Posts",
      children: (
        <Table
          key="bonus_content"
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={bonusContentData}
          rowKey={(record) => record.key}
        />
      ),
    },
    {
      key: "2",
      label: "Influencer Packages",
      children: (
        <Table
          key="packages"
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={packagesData}
          rowKey={(record) => record.key}
        />
      ),
    },
  ];

  useEffect(() => {
    const fetchData = () => {
      axios.get(SERVER_URL + "/posts").then(function (res) {
        const { posts, influencers } = res.data;
        setBonusContentData(posts.filter((post) => post.is_package === 0));
        setPackagesData(posts.filter((post) => post.is_package === 1));
        if (influencers.length > 0) {
          setInfluencerOptions(
            influencers.map((item) => {
              return {
                label: item.name,
                value: item.id,
              };
            })
          );
        }
        setLoading(false);
      });
    };
    fetchData();
  }, []);

  const openAddPostModal = () => {
    setIsOpenPostModal(true);
  };

  const closeAddPostModal = () => {
    setIsOpenPostModal(false);
    setFileUploading(false);
    setIsEdit(false);
    setIsActive(false);
    setIsPackage(false);
    setIsSkipWaitList(false);
    setIsPined(false);
    setIsHumanGenerated(false);
    setFileList([]);
    setPreviewList([]);
    form.resetFields();
  };

  const handlePostOk = async () => {
    try {
      await form.validateFields().then(async (values) => {
        setFileUploading(true);
        let preview = [];
        const files = fileList ? fileList.filter((file) => !file?.status) : [];
        if (values["influencer_id"] === undefined) {
          messageApi.error("Please select influencer");
          return;
        }
        if (values.price) {
          try {
            const price = Number(values.price);
            if (price < 0) {
              messageApi.error("Please input valid price");
              return;
            }
          } catch (e) {
            messageApi.error("Please input valid price");
            return;
          }
        }

        preview =
          values.preview && values.preview.length > 0
            ? values.preview[0].originFileObj
            : null;
        values.influencer_name = influencerOptions.find(
          (item) => item.value === values["influencer_id"]
        ).label;

        // Create a new FormData instance
        const formData = new FormData();
        formData.append("influencer_id", values["influencer_id"]);
        formData.append("influencer_name", values["influencer_name"]);
        formData.append("price", values["price"] ? values["price"] : 0);
        formData.append(
          "description",
          values["description"] ? values["description"] : ""
        );
        formData.append("is_active", isActive ? 1 : 0);
        formData.append("is_package", isPackage ? 1 : 0);
        formData.append("skip_wait_list", isSkipWaitList ? 1 : 0);
        formData.append("is_pined", isPined ? 1 : 0);
        formData.append("human_generated", isHumanGenerated ? 1 : 0);
        formData.append("tokens", values["tokens"] ? values["tokens"] : 0);
        formData.append("title", values["title"] ? values["title"] : "");

        if (preview) {
          // Add the preview file to the formData instance
          formData.append("preview", preview);
          formData.append("preview_name", preview.name);
        }

        // Add the files to the formData instance
        files.map((file) => {
          formData.append("files", file.originFileObj);
          formData.append("name", file.name);
          formData.append("type", file.type);
          return file;
        });

        if (isEdit) {
          formData.append(
            "post_media_ids",
            JSON.stringify(fileList.map((file) => file.uid))
          );
          formData.append(
            "prev_preview",
            previewList.map((file) => file.url)
          );
          formData.append("post_id", values.id);
          const { data } = await axios.put(SERVER_URL + "/posts", formData);
          setBonusContentData(data.filter((post) => post.is_package === 0));
          setPackagesData(data.filter((post) => post.is_package === 1));
        } else {
          const { data } = await axios.post(SERVER_URL + "/posts", formData);
          setBonusContentData(data.filter((post) => post.is_package === 0));
          setPackagesData(data.filter((post) => post.is_package === 1));
        }

        messageApi.open({
          type: "success",
          content: "Post saved successfully!",
        });
        closeAddPostModal();
      });
    } catch (error) {
      console.log("--ERROR--", error);
      if (error.response) {
        // Try to get the error message from the server otherwise set it to a default message
        const errorMessage =
          error.response.data.error || "Something went wrong!";

        messageApi.open({
          type: "error",
          content: errorMessage,
        });
      }
      setFileUploading(false);
    }
  };

  const handleOpenDeleteModal = (post_id) => {
    setIsDeleteModalOpen(true);
    setDeletePostId(post_id);
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeletePostId("");
  };
  const deleteRecord = async () => {
    try {
      const { data } = await axios.delete(SERVER_URL + "/posts", {
        data: {
          post_id: deletePostId,
        },
      });
      setBonusContentData(data.filter((post) => post.is_package === 0));
      setPackagesData(data.filter((post) => post.is_package === 1));

      messageApi.open({
        type: "success",
        content: "Post deactivate successfully!",
      });
      handleCloseDeleteModal();
    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message,
      });
    }
  };

  function editDetail(event, record) {
    event.stopPropagation();
    setIsEdit(true);
    setIsActive(record.is_active === 1);
    setIsPackage(record.is_package === 1);
    setIsSkipWaitList(record.is_skip_wait_list === 1);
    setIsPined(record.is_pined === 1);
    setIsHumanGenerated(record.human_generated === 1);

    form.setFieldsValue({
      id: record.id,
      influencer_id: record.influencer_id,
      price: record.price,
      description: record.description,
      is_active: record.is_active === 1,
      is_package: record.is_package === 1,
      is_skip_wait_list: record.is_skip_wait_list === 1,
      tokens: record.tokens,
      title: record.title,
      slug: record.slug,
    });
    setPreviewList([
      {
        uid: record.preview_url,
        name: record.preview_url,
        status: "done",
        url: record.preview_url,
      },
    ]);
    setFileList(
      record.media_urls.map((media) => {
        return {
          uid: media.id,
          name: media.url,
          status: "done",
          url: media.url,
        };
      })
    );
    openAddPostModal();
  }

  const onChangeActive = (e) => {
    setIsActive(e.target.checked);
  };

  const onChangePackage = (e) => {
    if (e.target.checked === false) {
      setIsSkipWaitList(false);
    }
    setIsPackage(e.target.checked);
  };

  const onChangeSkipWaitList = (e) => {
    if (e.target.checked === false) {
      setIsSkipWaitList(false);
    }
    setIsSkipWaitList(e.target.checked);
  };

  const onChangePined = (e) => {
    setIsPined(e.target.checked);
  };

  const onChangeHumanGenerated = (e) => {
    setIsHumanGenerated(e.target.checked);
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const onChange = async (key) => {
    setActiveKey(key);
  };

  return (
    <div>
      {contextHolder}
      <div>
        <Button type="primary" onClick={() => openAddPostModal()}>
          Add Post
        </Button>
      </div>
      <div>
        {loading ? (
          <BarLoader
            color="#1677ff"
            cssOverride={{
              display: "block",
              margin: "10vh auto",
              borderColor: "red",
            }}
            size={150}
          />
        ) : (
          <Tabs activeKey={activeKey} items={items} onChange={onChange} />
        )}
      </div>

      <Modal
        title={isEdit ? "Edit Post" : "Add Post"}
        okText={fileUploading ? "Uploading..." : "OK"}
        open={isOpenPostModal}
        onOk={handlePostOk}
        onCancel={closeAddPostModal}
        width={1000}
        okButtonProps={{ disabled: fileUploading }}
        cancelButtonProps={{ disabled: fileUploading }}
      >
        <Form form={form} name="dynamic_rule" layout="vertical">
          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ width: "100%" }}>
              <Form.Item
                {...formItemLayout}
                name="influencer_id"
                label="Influencer"
                rules={[
                  { required: true, message: "Please select influencer" },
                ]}
              >
                <Select
                  name="influencer_id"
                  options={influencerOptions}
                  rules={[
                    { required: true, message: "Please select influencer" },
                  ]}
                />
              </Form.Item>

              <Form.Item {...formItemLayout} name="price" label="Price, $">
                <Input type="number" min={0} placeholder="Please input price" />
              </Form.Item>

              <Form.Item {...formItemLayout} name="tokens" label="Tokens">
                <Input
                  type="number"
                  min={0}
                  placeholder="Please input tokens"
                />
              </Form.Item>

              <Form.Item {...formItemLayout} name="title" label="Title">
                <Input type="text" placeholder="Please input title" />
              </Form.Item>

              <Form.Item {...formItemLayout} name="slug" label="Slug">
                <Input type="text" disabled={true} />
              </Form.Item>

              <Form.Item {...formItemLayout} name="is_active">
                <Checkbox checked={isActive} onChange={onChangeActive}>
                  Is Active
                </Checkbox>
              </Form.Item>

              <Form.Item {...formItemLayout} name="is_package">
                <div style={{ color: "red" }}>
                  Package product used for wordpress site. Did't show in bonus
                  contetnt section.
                </div>
                <Checkbox checked={isPackage} onChange={onChangePackage}>
                  Is Package
                </Checkbox>
              </Form.Item>

              {isPackage && (
                <Form.Item {...formItemLayout} name="skip_wait_list">
                  <Checkbox
                    checked={isSkipWaitList}
                    onChange={onChangeSkipWaitList}
                  >
                    Is Skip Wait List
                  </Checkbox>
                </Form.Item>
              )}

              <Form.Item {...formItemLayout} name="is_pined">
                <div style={{ color: "red" }}>
                  If pined product show on top. Only one product may be pined.
                </div>
                <Checkbox checked={isPined} onChange={onChangePined}>
                  Is Pined
                </Checkbox>
              </Form.Item>

              <Form.Item {...formItemLayout} name="human_generated">
                <Checkbox
                  checked={isHumanGenerated}
                  onChange={onChangeHumanGenerated}
                >
                  Is Human Generated
                </Checkbox>
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="id"
                style={{ display: "none" }}
              >
                <Input type="hidden" placeholder="" />
              </Form.Item>
            </div>

            <div style={{ width: "100%" }}>
              <Form.Item
                name="preview"
                label="Preview Video or Photo (max: 1 video or photo)"
                getValueFromEvent={normFile}
              >
                <Upload
                  name="preview"
                  action={
                    "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                  }
                  listType="picture"
                  fileList={previewList}
                  multiple={false}
                  beforeUpload={() => false}
                  maxCount={1}
                  onChange={(e) => {
                    setPreviewList(e.fileList);
                  }}
                  onRemove={(e) => {
                    setPreviewList([]);
                  }}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>

              <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                <SortableContext
                  items={fileList.map((i) => i.uid)}
                  strategy={verticalListSortingStrategy}
                >
                  <Form.Item
                    name="files"
                    label="Photo (max: 25 photo), Video (max: 1 video, maxLength: 1h)"
                    getValueFromEvent={normFile}
                  >
                    <Upload
                      name="files"
                      action={
                        "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                      }
                      listType="picture"
                      fileList={fileList}
                      multiple={true}
                      beforeUpload={() => false}
                      maxCount={25}
                      onChange={(e) => {
                        setFileList(e.fileList);
                      }}
                      onRemove={(e) => {
                        setFileList(
                          fileList.filter((file) => file.uid !== e.uid)
                        );
                      }}
                      itemRender={(originNode, file) => (
                        <DraggableUploadListItem
                          originNode={originNode}
                          file={file}
                        />
                      )}
                    >
                      <div style={{ color: "red" }}>
                        The first photo should be the same as in the preview
                        field
                      </div>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                  </Form.Item>
                </SortableContext>
              </DndContext>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <Form.Item
              {...formItemLayout}
              name="description"
              label="Description"
            >
              <TextArea rows={3} placeholder="Please input description" />
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <Modal
        title={"Deactivate post"}
        open={isDeleteModalOpen}
        onOk={deleteRecord}
        onCancel={handleCloseDeleteModal}
      >
        <div>Deactivate Post?</div>
      </Modal>
    </div>
  );
};

export default Posts;
